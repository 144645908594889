import * as React from 'react'
import styles from './styles.module.css'
import Gmap from './google-map/GoogleMap'
import PlacesAutocomplete from './google-map/PlacesAutocomplete'
import GeneralPagination from './Pagination'
import SearchBox from './SearchBox'
import FilterBox from './FilterBox'
// const GeneralPagination = require('./Pagination')

interface Props {
  text: string
}
interface searchBarProps {
  setSearch: (searchInput: React.SetStateAction<string>) => void
}
interface filterBarProps {
  children?: React.ReactChild | React.ReactChild[]
}
interface mapProps {
  location: object
  marker1: object
  marker2: object
}
interface placesProps {
  setAddress: (searchInput: React.SetStateAction<string>) => void
  setAddressCords: (searchInput: React.SetStateAction<string>) => void
  label: string
  textSX: object
}

interface PropsPagination {
  pageSize: number
  setPageSize: (setPageSize: React.SetStateAction<number>) => void
  totalPages: number
  setPage: (setPage: React.SetStateAction<number>) => void
  anchorEl: number
  setAnchorEl: (setAnchorEl: React.SetStateAction<number>) => void
}

export const ExampleComponent = ({ text }: Props) => {
  return <div className={styles.test}>{text}</div>
}

export const Pagination = ({
  pageSize,
  setPageSize,
  totalPages,
  setPage,
  anchorEl,
  setAnchorEl
}: PropsPagination) => {
  return (
    <GeneralPagination
      pageSize={pageSize}
      setPageSize={setPageSize}
      totalPages={totalPages}
      setPage={setPage}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
    />
  )
}

export const GoogleMap = ({ location, marker1, marker2 }: mapProps) => {
  return <Gmap location={location} marker1={marker1} marker2={marker2} />
}

export const GooglePlacesAutocomplete = ({
  setAddress,
  setAddressCords,
  label,
  textSX
}: placesProps) => {
  return (
    <PlacesAutocomplete
      setAddress={setAddress}
      setAddressCords={setAddressCords}
      label={label}
      textSX={textSX}
    />
  )
}

export const SearchBar = ({ setSearch }: searchBarProps) => {
  return <SearchBox setSearch={setSearch} />
}

export const FilterBar = ({ children }: filterBarProps) => {
  return <FilterBox>{children}</FilterBox>
}
