import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
// G maps
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api'
import mapStyles from './mapStyles'
import icon from 'marker.svg'

const defaultLibraries = ['places', 'geocoding']

const Gmap = ({ location, marker1, marker2 }) => {
  const defaultCenter = { lat: 25.66, lng: -100.3396 }

  const defaultOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: mapStyles
  }
  const containerStyle = {
    width: '100%',
    height: '100%'
  }

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAnK1Av0g8uhLcCpw7JSGBiYnmt8xTTM9o',
    libraries: defaultLibraries
  })

  return (
    <Fragment>
      {isLoaded && (
        <GoogleMap
          zoom={12}
          center={location !== {} && location ? location : defaultCenter}
          mapContainerClassName='map-container'
          mapContainerStyle={containerStyle}
          options={defaultOptions}
        >
          {marker1 && marker1 !== {} && (
            <Marker position={marker1} icon={{ url: icon }} />
          )}
          {marker2 && marker2 !== {} && (
            <Marker position={marker2} icon={{ url: icon }} />
          )}
        </GoogleMap>
      )}
    </Fragment>
  )
}

Gmap.propTypes = {
  marker1: PropTypes.any,
  marker2: PropTypes.any,
  location: PropTypes.any
}

export default Gmap
