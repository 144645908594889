import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Pagination, Button, Menu, MenuItem } from '@mui/material'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import { useTheme } from '@mui/material/styles'

const GeneralPagination = ({
  setPage,
  setPageSize,
  totalPages,
  pageSize,
  anchorEl,
  setAnchorEl
}) => {
  const theme = useTheme()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item xs={12} sx={{ p: 3 }}>
      <Grid container justifyContent='space-between' spacing={2}>
        <Grid item>
          <Pagination
            count={totalPages}
            onChange={(e, page) => {
              setPage(page)
            }}
            color='primary'
          />
        </Grid>
        <Grid item>
          <Button
            size='large'
            sx={{ color: theme.palette.grey[900] }}
            color='secondary'
            endIcon={<ExpandMoreRoundedIcon />}
            onClick={handleClick}
          >
            {pageSize} Rows
          </Button>
          <Menu
            id='menu-user-list-style1'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            variant='selectedMenu'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            <MenuItem onClick={() => setPageSize(10)}> 10 Rows</MenuItem>
            <MenuItem onClick={() => setPageSize(20)}> 20 Rows</MenuItem>
            <MenuItem onClick={() => setPageSize(30)}> 30 Rows </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}
GeneralPagination.propTypes = {
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
  totalPages: PropTypes.number,
  setPage: PropTypes.func,
  anchorEl: PropTypes.number,
  setAnchorEl: PropTypes.func
}

export default GeneralPagination
