/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
// React
import React from 'react';
// MUI
import { Popover, Stack, ButtonBase } from '@mui/material';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { TuneRounded } from '@mui/icons-material';

export default function FilterBox({ children }) {

    return (
        <PopupState variant="popover" popupId="filterBox">
            {(popupState) => (
                <Stack direction="column" spacing={2} alignItems="center" justifyContent="center">
                    <ButtonBase sx={{ width: '100%', borderRadius: '8px', height: '100%' }} {...bindTrigger(popupState)}>
                        <Stack
                            sx={{
                                width: '100%',
                                background: 'white',
                                borderRadius: '8px',
                                border: '1px solid rgba(147, 166, 192, 0.35)',
                                height: '50px'
                            }}
                            spacing="12px"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <TuneRounded
                                sx={{
                                    fontSize: '20px'
                                }}
                            />
                        </Stack>
                    </ButtonBase>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        sx={{ mt: 1 }}
                        PaperProps={{
                            sx: {
                                width: '350px',
                                pb: 3,
                                '&::-webkit-scrollbar': { width: '5px' },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: (theme) => theme.palette.grey[300],
                                    borderRadius: '20px'
                                }
                            }
                        }}
                    >
                        <Stack
                            direction="column"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                pt: 1
                            }}
                        >
                            {children}
                        </Stack>
                    </Popover>
                </Stack>
            )}
        </PopupState>
    );
}

FilterBox.propTypes = {
    children: PropTypes.any
};
