import PropTypes from 'prop-types'
// React
import React from 'react'
// MUI
import { Autocomplete, TextField } from '@mui/material'
// Google
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng
} from 'use-places-autocomplete'

const PlacesAutocomplete = ({ setAddress, setAddressCords, label, textSX }) => {
  const {
    value,
    setValue,
    suggestions: { data },
    clearSuggestions
  } = usePlacesAutocomplete({ callbackName: 'placesCallback' })

  const handleSelect = async (e) => {
    const address = e.target.innerText
    console.log(e.target.innerText)
    setValue(address, false)
    setAddress(address)
    clearSuggestions()

    const results = await getGeocode({ address })
    const { lat, lng } = await getLatLng(results[0])
    setAddressCords({ lat, lng })
  }

  return (
    <Autocomplete
      options={data || []}
      getOptionLabel={(option) => option.description}
      onChange={handleSelect}
      renderInput={(params) => (
        <TextField
          fullWidth
          sx={textSX}
          {...params}
          label={label || 'Address'}
          value={value || ''}
          onChange={(e) => setValue(e.target.value)}
        />
      )}
    />
  )
}

PlacesAutocomplete.propTypes = {
  setAddress: PropTypes.func,
  setAddressCords: PropTypes.func,
  label: PropTypes.string,
  textSX: PropTypes.any
}

export default PlacesAutocomplete
